import React from "react"

import { Container, SEOPage } from "../components";
import { Breadcrumb } from "site/src/components";
import { StyledPage, StyledTimeline } from "site/src/components/css";

function History() {
  const SEO = {
    entity: "page",
    title: "History",
    keywords: `history,about us`,
    description: `A century of events. Where and how Barrow started.`,
    url: `/history`
  }

  return (
    <Container page="content" breadcrumbs={false}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        entity={SEO.entity}
        title={SEO.title}
        keywords={SEO.keywords}
        description={SEO.description}
        url={SEO.url}
      />

      <div>
        <Breadcrumb show={true} data={{ type: "content", title: "History", image: "https://res.cloudinary.com/barrow/image/upload/f_auto/Barrow_page_headers-group_desktop_cxvqpy.jpg" }} />
        <StyledPage>

          <StyledTimeline>
            <div class="timeline">
              <div className="top_bar"></div>
              <div class="container left none">
                <div class="content left_side">
                  <h3>A Century of events</h3>
                  <h1>Where and how Barrow started.</h1>
                </div>
              </div>
              <div class="container middle none">
                <div class="content date">
                  <div className="square_box border">
                    <div className="middle_content">
                      <p className="inline">est</p><h2 className="inline">1897</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h5>1897 - John Barrow builders is established in Bertrams</h5>
                      <p>As the discovery of the electron and the development of the diesel engine  usher in a new  era,John Barrow Builders is established in Bertrarns,Johannesburg.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h2>1902</h2>
                    </div>
                  </div>
                </div>
                <div class="content_blocks" style={{ height: 120 }}>
                  <div class="container right">
                    <h5 className="para_right" style={{ marginTop: 0 }}>First buildings at St John's College, Houghton.</h5>
                  </div>
                </div>
                <div class="content_blocks" style={{ height: 160 }}>
                  <div class="container right none">
                    <img alt="St Johns College" className="img_right" src="https://res.cloudinary.com/barrow/image/upload/v1621860782/AAEAAQAAAAAAAAAAAAAAJDE4YTczYmQzLWUwNjItNGRmZC05YWNmLTNhNGRkNTY3MjYxZg_dbbkre.webp" />
                  </div>

                </div>

              </div>


              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h5>1921</h5>
                    </div>
                  </div>
                </div>
                <div class="content_blocks">
                  <div class="container left none">
                    <p className="para_left">Family home, Bleak House, built in Dunkeld.</p>
                    <p className="para_left">The Barrow home, the very antithesis of bleakness, was wryly, but humourously, named by Albert Barrow after a harrowing first night, when "there were no lights, the wind howled outside, and to cap it all, a celluloid holder in the bathroom caught alight, filling the house with smoke and the younger members of the family with alarm.</p>
                    <p className="para_left">Albert Barrow  elected President of the Witwatersrand Master Builders Association.</p>
                    {/* <img alt="Bleak House" className="img_left" src="https://res.cloudinary.com/barrow/image/upload/v1621863140/Bleak_House_ettird.jpg" /> */}
                  </div>
                  <div class="container right none">
                    <img alt="Bleak House" className="img_right" src="https://res.cloudinary.com/barrow/image/upload/v1621863140/Bleak_House_ettird.jpg" />
                  </div>
                </div>
              </div>
              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h2>1922</h2>
                    </div>
                  </div>
                </div>
                <div class="content_blocks">
                  <div class="container left ">
                    <p className="para_left">Construction begins on the Central Block at the University of the Witwatersrand.</p>
                  </div>
                </div>
                <div class="content_blocks">
                  <div class="container right none">
                    <img alt="University of the Witwatersrand" className="img_right" src="https://res.cloudinary.com/barrow/image/upload/v1621863180/Wits_central_block_started_1922_hsrnfw.jpg" />
                  </div>
                </div>

              </div>
              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h2>1931</h2>
                      <p>In the midst of the Great De­pression, Barrow builds the new library at the University of the Witwatersrand in record time. In the same year, Albert Barrow was elected President of the Building Industries Federation of South Africa (BIFSA).</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h5>1934</h5>
                    </div>
                  </div>
                </div>
                <div class="content_blocks">
                  <div class="container right none" style={{ height: 100 }}>
                    <p className="para_right">Family business registered as a company, known as John Barrow (Pty) Limited.</p>
                  </div>
                </div>
              </div>

              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h2>1947</h2>
                      <img alt="1947" src="https://res.cloudinary.com/barrow/image/upload/v1621863256/Saint_Johns_college_Houghton_1927_north_wing_qnhjjz.jpg" />
                    </div>
                  </div>
                </div>
                <div class="content_blocks">
                  <div class="container left none">
                    <p className="para_left">John Barrow II becomes President of the Witwatersrand Master Builders Association, in his father's footsteps, and is instrumental in resolving the costly builders' strike.</p>
                  </div>
                </div>
              </div>


              <div class="container middle none">
                <div class="content_blocks" style={{ height: 320 }}>
                  <div class="container left" style={{ display: "inline-block" }}>
                    <h5 className="para_left" style={{ textAlign: "right", marginTop: 10 }}>1960's</h5>
                  </div>

                  <div class="container right none" style={{ display: "inline-block", float: "left" }}>
                    <p className="para_right">As South Africa becomes a Republic and Beatle mania takes hold ... Barrow goes about its business, constructing new buildings for St John's College, Medical Research and University of the Witwatersrand. The company also welcomes the fourth generation of Barrows,John III, David and Douglas.</p>
                  </div>
                </div>
              </div>

              <div class="container middle none">
                <div class="content_blocks">
                  <div class="container left" style={{ display: "inline-block" }}>
                    <p className="para_left">Apart from projects for corporate headquarters and institutions, Barrow was responsible for erecting many apartment blocks during the decade, including Glen­dower Heights and Majestic Mansions, Parktown.</p>
                  </div>

                  <div class="container right none" style={{ display: "inline-block", float: "left" }}>
                    <h5 className="para_right" style={{ textAlign: "right", marginTop: 10 }}>1970's</h5>
                  </div>
                </div>
              </div>

              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h5>1975</h5>
                    </div>
                  </div>
                </div>
                <div class="content_blocks">
                  <div class="container right none" style={{ height: 150 }}>
                    <p className="para_right">The third Barrow, John III, becomes President of the Master Builders Association.</p>
                  </div>
                </div>
              </div>

              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h2>1979</h2>
                      <h5>Barrow diversifies into property development -</h5>
                      <p>a bold step in the light of many commercial failures by other property developers during this decade.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container middle none">
                <div class="content_blocks" style={{ height: 500 }}>
                  <div class="container left" style={{ display: "inline-block" }}>
                    <h5 className="para_left" style={{ textAlign: "right", marginTop: 10 }}>1980's</h5>
                  </div>

                  <div class="container right none" style={{ display: "inline-block", float: "left" }}>
                    <p className="para_right"><h5>John Barrow III is appointed as President of BIFSA.</h5>
                      <p>Some major building contracts included the Blue Circle Head Office in Rosebank, Head Office for Deloitte Haskins Sells in Sandton, Standard Bank and Trek Head Office both in Randburg.</p></p>
                  </div>
                </div>
              </div>


              <div class="container middle none">
                <div class="content_blocks">
                  <div class="container left ">
                    <p className="para_left">Barrow Dewar formed to continue expansion in property development. The first project was the single-storey Woodmead office park, 12 000 square metres of offices in a landscaped garden environment, which won many awards, including the South African Property Owners Association award for best office park development.</p>
                  </div>
                </div>

              </div>


              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h2>1992</h2>
                      <h5>John Barrow II dies and is remembered for his 66 years of service to the building industry and for his 25 years as chairman of Fedsure.</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container middle none">
                <div class="content_blocks">
                  <div class="container left" style={{ display: "inline-block" }}>
                    <p className="para_left">Barrow Projects formed to provide independent management services for the development of building and engineering projects, awarded R400 million ABSA contract in its first year.</p>
                  </div>

                  <div class="container right none" style={{ display: "inline-block", float: "left" }}>
                    <h5 className="para_right" style={{ textAlign: "right", marginTop: 10 }}>1995</h5>
                  </div>
                </div>
              </div>

              <div class="container middle none">
                <div class="content">
                  <div className="square_box">
                    <div className="middle_content">
                      <h2>1997</h2>
                      <h5>Barrow celebrates 100 years of building an industry.</h5>
                      <p>and the future . . . as the fourth Barrow, John Robert becomes President of the Gauteng Master Builders Association.</p>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </StyledTimeline>

        </StyledPage>
      </div >
    </Container >
  )
}

export default History